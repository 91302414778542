import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './../../../../components/CardTable/index.scss';
import { ReactSortable } from 'react-sortablejs';
import { Tooltip } from 'element-react';
import * as ReactIcons from '@coreui/icons';
import { CIcon } from '@coreui/icons-react';

const spin = (
  <div className="loadingTable">
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

function BookmarkList(props) {
  const { t } = useTranslation();
  const {
    bookmarks,
    curBookmarks,
    isEditBookmark,
    editBookmark,
    setBookmarkList,
    removeBookmark,
    openCreateFromCard,
    endDragDrop
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(Array(bookmarks.length).fill(false));
  const [cardIndex, setCardIndex] = useState(-1);

  const toggleDropdown = (event, index) => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
    if (newDropdownOpen[index]) {
      setCardIndex(index);
    } else {
      setCardIndex(-1);
    }
    event.target.focus();
    event.stopPropagation();
  };

  const addBookmark = () => {
    openCreateFromCard(true);
  };

  const handleEdit = (event, bookmarkId) => {
    event.stopPropagation();
    editBookmark(bookmarkId);
  };

  const handleRemove = bookmarkId => {
    removeBookmark(bookmarkId);
  };

  const clickRedirect = url => {
    if (!isEditBookmark) window.open(url, '_blank');
  };

  const onDragDropEnds = (oldIndex, newIndex) => {
    if (oldIndex === cardIndex) {
      const newDropdownOpen = [...dropdownOpen];
      if (bookmarks.length === newIndex) {
        newDropdownOpen[newIndex - 1] = !newDropdownOpen[newIndex - 1];
        setDropdownOpen(newDropdownOpen);
        setCardIndex(newIndex - 1);
      } else {
        newDropdownOpen[newIndex] = !newDropdownOpen[newIndex];
        setDropdownOpen(newDropdownOpen);
        setCardIndex(newIndex);
      }
    } else {
      setCardIndex(-1);
    }
    endDragDrop();
  };

  const onDragDropStart = (oldIndex, newIndex) => {
    setDropdownOpen([]);
  };

  const renderAddBookmarkList = () => {
    if (curBookmarks.length < 20) {
      return (
        <div className="col-xs-12 col-sm-4 col-md-3 ignoreDrag">
          <Card
            className={'card-default card-shadow text-center grid-items card shadow-sm rounded-3 overflow-hidden'}
            style={{ backgroundColor: '#fff' }}
            onClick={() => addBookmark()}
          >
            {
              <div className="card-img">
                <label className="image-item" style={{ paddingTop: '50px' }}>
                  <span>
                    <i
                      className="material-icons"
                      style={{
                        fontSize: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#f66'
                      }}
                    >
                      <b>{'add'}</b>
                    </i>
                  </span>
                </label>
              </div>
            }
          </Card>
        </div>
      );
    } else {
      if (bookmarks.length === 0) {
        return (
          <div className="p-b-20 m-auto text-center text-muted d-block">
            <span className="material-icons md-36">error_outline</span>
            <div className="d-block mt-1">{t('bookmark.notFound')}</div>
          </div>
        );
      }
    }
  };

  return (
    <div className="resource-list d-flex flex-wrap p-b-0 row">
      {bookmarks.length === 0 ? (
        renderAddBookmarkList()
      ) : (
        <ReactSortable
          list={bookmarks}
          setList={setBookmarkList}
          className="row"
          animation="200"
          fallbackOnBody={true}
          swapThreshold="0.5"
          filter=".ignoreDrag"
          preventOnFilter={true}
          onEnd={({ oldIndex, newIndex }) => onDragDropEnds(oldIndex, newIndex)}
          onStart={({ oldIndex, newIndex }) => onDragDropStart(oldIndex, newIndex)}
        >
          <>
            {bookmarks.map((bookmark, index) => (
              <div className={`col-xs-12 col-sm-4 col-md-3 ${!isEditBookmark ? 'ignoreDrag' : ''}`}>
                <Card
                  className={`card-default card-shadow text-center ${isEditBookmark ? 'disable-hover' : ''}`}
                  key={`resource_card_index_${index}`}
                  onClick={() => clickRedirect(bookmark.bookmarkUrl)}
                >
                  {
                    <div className="card-buttons">
                      <Tooltip
                        effect="dark"
                        content={t('common.tooltip.editBookmark')}
                        placement="bottom"
                        style={{ marginRight: '-9px' }}
                      >
                        <Button
                          onClick={event => handleEdit(event, bookmark.bookmarkId)}
                          className="btn btn-default m-1"
                          style={{
                            width: '20px',
                            height: '25px',
                            borderRadius: '5px 0 0 5px'
                          }}
                        >
                          <span className="edit">
                            <i
                              className="material-icons"
                              style={{
                                fontSize: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: '1px',
                                marginTop: '-2px'
                              }}
                            >
                              edit
                            </i>
                          </span>
                        </Button>
                      </Tooltip>

                      <Dropdown isOpen={dropdownOpen[index]} toggle={event => toggleDropdown(event, index)}>
                        <DropdownToggle
                          caret
                          className="btn btn-default m-1"
                          style={{
                            width: '20px',
                            height: '25px',
                            borderRadius: '0 5px 5px 0',
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            paddingRight: '7px'
                          }}
                        />
                        <DropdownMenu right={true} style={{ minWidth: '100%' }}>
                          <DropdownItem onClick={event => handleEdit(event, bookmark.bookmarkId)}>
                            <i className="material-icons text-muted m-r-5">edit</i> {t('common.button.edit')}
                          </DropdownItem>
                          <DropdownItem onClick={() => handleRemove(bookmark.bookmarkId)}>
                            <i className="material-icons text-muted m-r-5">delete_outline</i>{' '}
                            {t('common.button.delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  }
                  {
                    <div className="card-img">
                      <span className="img-helper"></span>
                      <label className="image-item" style={{ paddingTop: '44px' }}>
                        {bookmark.icon ? (
                          <span>
                            <CIcon icon={ReactIcons[bookmark.icon]} width={100} color={bookmark.color} />
                          </span>
                        ) : (
                          <img src={bookmark.icon.toLowerCase()} width="100%" height="100%" />
                        )}
                      </label>
                    </div>
                  }
                  {bookmark.title && (
                    <div className="card-header">
                      <div className="card-title">{bookmark.title}</div>
                    </div>
                  )}
                </Card>
              </div>
            ))}
            {bookmarks.length < 20 && renderAddBookmarkList()}
          </>
        </ReactSortable>
      )}
    </div>
  );
}

export default BookmarkList;
